import React, { useState } from 'react';

import DefaultLayout from '../../layouts';
import ContentSection from '../../layouts/content-section';
import Metadata from '../../components/metadata';

const MEMBERSHIP_CHECK_LOADING = 'MEMBERSHIP_CHECK_LOADING';
const MEMBERSHIP_CHECK_SUCCESSFUL = 'MEMBERSHIP_CHECK_SUCCESSFUL';
const MEMBERSHIP_CHECK_ERROR = 'MEMBERSHIP_CHECK_ERROR';

const CheckMembership = ({ location }) => {
  const [email, setEmail] = useState('');
  const [membershipCheckState, setMembershipCheckState] = useState(null);

  const checkMembership = (event) => {
    event.preventDefault();

    setMembershipCheckState(MEMBERSHIP_CHECK_LOADING);
    fetch('https://registry.naaspeksi.fi/check_membership', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((response) =>
        setMembershipCheckState(
          response.ok ? MEMBERSHIP_CHECK_SUCCESSFUL : MEMBERSHIP_CHECK_ERROR
        )
      )
      .catch((_) => setMembershipCheckState(MEMBERSHIP_CHECK_ERROR));
  };

  return (
    <DefaultLayout>
      <Metadata
        page={{
          title: 'Tarkista jäsenyys - NääsPeksi',
          excerpt: '',
        }}
        location={location}
      />

      <ContentSection>
        <h1>Tarkista jäsenyys</h1>
        <p>
          Voit tarkistaa jäsenyytesi tilan alla olevalla lomakkeella. Antamaasi
          sähköpostiosoitteeseen lähetetään linkki, jolla näet jäsenyystietosi.
          Muista tarkistaa myös roskapostikansio!
        </p>

        <form onSubmit={checkMembership}>
          <label htmlFor="email">Sähköpostiosoite: </label>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button disabled={membershipCheckState === MEMBERSHIP_CHECK_LOADING}>
            Tarkista
          </button>
        </form>

        {membershipCheckState === MEMBERSHIP_CHECK_SUCCESSFUL && (
          <span style={{ color: 'green' }}>
            Saat linkin sähköpostiisi hetken kuluttua. Muista tarkistaa myös
            roskapostikansio.
          </span>
        )}

        {membershipCheckState === MEMBERSHIP_CHECK_ERROR && (
          <span style={{ color: '#ee3333' }}>
            Tapahtui virhe, yritä myöhemmin uudelleen.
          </span>
        )}
      </ContentSection>
    </DefaultLayout>
  );
};

export default CheckMembership;
